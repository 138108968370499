<template>
	<div>
		<div v-if="loading"><v-skeleton-loader type="paragraph" class="mx-auto"></v-skeleton-loader></div>
		<div v-else>
			<Apexchart type="radar" :options="chartOptions" :series="series"></Apexchart>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueApexCharts from 'vue-apexcharts';

export default {
	name: 'RulesFamilyRadarChart',
	components: { Apexchart: VueApexCharts },
	computed: {
		...mapGetters('profile', { rulesUser: 'rulesUser' }),
		...mapGetters('familyTags', { familyTags: 'familyTags' }),
	},
	data() {
		return {
			loading: true,
			chartOptions: {
				chart: { toolbar: { show: false } },
				colors: ['#15c39a'],
				dataLabels: { enabled: true, formatter: (val) => val + '%', style: { fontSize: '14px' } },
				plotOptions: { radar: { polygons: { strokeColor: '#e9e9e9', fill: { colors: ['#f8f8f8', '#fff'] } } } },
				tooltip: {
					y: {
						formatter: (val, opts) => {
							// Ajouter le nombre de règles dans le tooltip
							const familyName = this.chartOptions.xaxis.categories[opts.dataPointIndex];
							const data = this.familyProgressMap ? this.familyProgressMap.get(familyName) : null;
							if (data) {
								return `${val}% (${data.totalRules} règles)`;
							}
							return `${val}%`;
						},
					},
				},
				xaxis: {
					categories: ['Accords', 'Conjugaison', 'Grammaire', 'Homonymes', 'Syntaxe'],
					labels: { style: { fontSize: '15px' } },
				},
				yaxis: {
					max: 100,
					min: 0,
					tickAmount: 10,
					labels: {
						formatter: (val, i) => {
							if (i % 2 === 0) return Math.round(val) + '%';
							else return '';
						},
					},
				},
			},
			series: [{ name: 'Progression moyenne', data: [0, 0, 0, 0, 0] }],
			familyProgressMap: null,
		};
	},
	async created() {
		if (this.familyTags == null) await this.$store.dispatch('familyTags/getAllFamilyTags');
		this.calculateProgressByFamilyTag();
	},
	watch: {
		rulesUser() {
			this.calculateProgressByFamilyTag();
		},
		familyTags() {
			this.calculateProgressByFamilyTag();
		},
	},
	methods: {
		updateChartData(data) {
			this.series[0].data = data;
		},

		// Méthode pour prétraiter et simplifier les règles
		preprocessRules(rules) {
			return rules.map((rule) => {
				// Extraire le nom de la famille depuis la structure imbriquée
				let familyName = null;
				if (
					rule.infosExo &&
					rule.infosExo.length > 0 &&
					rule.infosExo[0].story_score &&
					rule.infosExo[0].story_score.length > 0 &&
					rule.infosExo[0].story_score[0].story_id &&
					rule.infosExo[0].story_score[0].story_id.rule_tags_id &&
					rule.infosExo[0].story_score[0].story_id.rule_tags_id.family_tag_id
				) {
					const familyTagId = rule.infosExo[0].story_score[0].story_id.rule_tags_id.family_tag_id;
					if (typeof familyTagId === 'object' && familyTagId.name) {
						familyName = familyTagId.name;
					}
				}

				// Retourner un objet simplifié
				return {
					id: rule.id,
					name: rule.name,
					score: rule.score || 0,
					pointTarget: 150, // Cible standard est 150
					familyTagName: familyName,
				};
			});
		},

		calculateProgressByFamilyTag() {
			// Vérifier si les données sont disponibles
			if (!this.rulesUser || !this.familyTags) {
				console.error('rulesUser ou familyTags manquant');
				this.loading = true;
				return;
			}

			console.log('Nombre de règles à traiter:', this.rulesUser.length);

			// Examiner en détail la structure des 3 premières règles pour comprendre le format
			if (this.rulesUser && this.rulesUser.length > 0) {
				console.log('Structure détaillée des 3 premières règles:');
				for (let i = 0; i < Math.min(3, this.rulesUser.length); i++) {
					console.log(`Règle ${i}:`, JSON.stringify(this.rulesUser[i], null, 2));
				}
			}

			// Prétraiter et simplifier les règles
			const simplifiedRules = this.preprocessRules(this.rulesUser);
			console.log('Règles simplifiées (3 premières):', simplifiedRules.slice(0, 3));

			// Initialiser la map pour stocker les données de progression par famille
			const familyProgressMap = new Map();

			// Initialiser les données pour chaque famille basé sur les catégories du graphique
			this.chartOptions.xaxis.categories.forEach((familyName) => {
				familyProgressMap.set(familyName, {
					totalRules: 0,
					totalProgressPercentage: 0,
				});
			});

			// Compteur pour les règles sans famille
			let rulesWithoutFamily = 0;

			// Parcourir toutes les règles simplifiées
			simplifiedRules.forEach((rule, index) => {
				// Vérifier si la règle a une famille
				if (!rule.familyTagName) {
					rulesWithoutFamily++;
					return;
				}

				// Vérifier si la famille est dans notre liste de catégories
				if (familyProgressMap.has(rule.familyTagName)) {
					const familyData = familyProgressMap.get(rule.familyTagName);
					familyData.totalRules++;

					// Calculer le pourcentage (score sur pointTarget)
					const progressPercentage = Math.min(100, (rule.score / rule.pointTarget) * 100);
					familyData.totalProgressPercentage += progressPercentage;

					// Log pour vérification (limité aux 10 premières règles)

					console.log(
						`Règle ${index}: ${rule.name}, Famille: ${rule.familyTagName}, Score: ${rule.score}/${
							rule.pointTarget
						} = ${progressPercentage.toFixed(2)}%`
					);
				}
			});

			// Log des règles sans famille
			if (rulesWithoutFamily > 0) {
				console.log(`Règles sans famille assignée: ${rulesWithoutFamily}`);
			}

			// Sauvegarder la map pour les tooltips
			this.familyProgressMap = familyProgressMap;

			// Calculer les moyennes pour chaque famille
			const chartData = this.chartOptions.xaxis.categories.map((familyName) => {
				const data = familyProgressMap.get(familyName);
				if (data && data.totalRules > 0) {
					// Calculer la moyenne
					const average = Math.round(data.totalProgressPercentage / data.totalRules);
					console.log(`Famille ${familyName}: ${data.totalRules} règles, moyenne: ${average}%`);
					return average;
				}

				console.log(`Famille ${familyName}: aucune règle trouvée`);
				return 0;
			});

			console.log('Valeurs finales pour le graphique:', chartData);
			this.updateChartData(chartData);
			this.loading = false;

			// Si toutes les valeurs sont à 0, afficher l'état de chargement
			if (chartData.every((val) => val === 0)) {
				console.warn("Aucune règle n'a pu être associée à une famille. Affichage de l'état de chargement.");
				this.loading = true;
			}
		},

		// Cette méthode n'est plus nécessaire car nous utilisons l'indicateur de chargement au lieu des données de test
	},
};
</script>

<style lang="scss" scoped></style>
