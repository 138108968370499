<template>
	<div>
		<v-row
			v-intro-autostart="autoStartTourRules"
			v-intro-autostart.config="autostartRulesConfig"
			v-intro-autostart:on.complete="onFinishTourRules"
			v-intro-autostart:on.exit="onFinishTourRules"
			class="mt-1"
		>
			<v-col cols="12" md="3" class="blockRules hide-scroll-bar">
				<div class="view-selector mb-3 d-none d-md-block">
					<v-btn-toggle v-model="viewMode" mandatory color="#2dc7ae" dense class="view-toggle">
						<v-btn value="list">
							<v-icon small>mdi-format-list-bulleted</v-icon>
							<span class="ml-1"></span>
						</v-btn>
						<v-btn value="kanban">
							<v-icon small>mdi-view-column</v-icon>
							<span class="ml-1"></span>
						</v-btn>
						<v-btn value="chart" class="d-none d-md-inline-flex" v-tooltip="'Graphique radar'">
							<v-icon small>mdi-chart-donut</v-icon>
						</v-btn>
					</v-btn-toggle>
				</div>
				<SearchAndFilter
					ref="searchAndFilter"
					@familySelected="familySelected"
					@statusSelected="statusSelected"
					@searchRules="searchRulesFind"
					id="search"
					v-intro="'<h2>Recherche les règles que tu veux revoir</h2>Utilise les filtres ou la barre de recherche'"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
				/>
				<CardsObjectivesRules
					class="CardsObjectivesRules-mobile"
					:totalRuleOfFormation="totalRulesOfFormation"
					:nbRulesValidated="nbRulesValidated"
					:nbRulesMemorized="nbRulesMemorized"
					:nbreRulesView="nbreRulesView"
					id="objective-rule"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
					v-intro="
						'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'
					"
				/>
				<div
					v-for="(rule, index) in rulesDisplay"
					class="mb-2 cardRules"
					:key="index"
					:id="index == 0 ? 'rule' : false"
					@click="handleRuleClick(rule)"
					v-scroll-to="{
						el: '#rule-' + rule.id,
						duration: 1000,
					}"
					v-intro="
						'<h2>Clique sur une règle pour la revoir</h2>Retrouve la description de la règle ainsi que les sessions associées.'
					"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
					v-intro-if="index == 0"
				>
					<RulesCards :rule="rule" :ruleSelected="getRuleSelectedName" />
				</div>
			</v-col>
			<v-col id="rulesInfo hide-scroll-bar" cols="12" md="9" class="rules-info-col">
				<CardsObjectivesRules
					class="CardsObjectivesRules-desktop"
					:totalRuleOfFormation="totalRulesOfFormation"
					:nbRulesValidated="nbRulesValidated"
					:nbRulesMemorized="nbRulesMemorized"
					:nbreRulesView="nbreRulesView"
					id="objective-rule"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
					v-intro="
						'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'
					"
				/>
				<div v-if="displayRules" class="rules-info-content">
					<RulesInfos
						v-if="!$vuetify.breakpoint.xsOnly"
						:infosRules="infosRules"
						:idFormation="orthographigFormation._id"
						@displayRuleFromSession="displayRuleFromSession"
					/>
				</div>
				<div v-else-if="viewMode === 'chart'" class="radar-chart-container">
					<div v-if="!rulesDisplay || rulesDisplay.length === 0" class="text-center py-8">
						<h3 class="mt-4 grey--text text--darken-1">Aucune règle disponible</h3>
						<p class="subtitle-1 grey--text">
							Complétez des exercices pour débloquer des règles et voir votre progression
						</p>
					</div>
					<RulesFamilyRadarChart v-else />
				</div>
				<div v-else class="text-center mt-8 grey--text">
					<v-icon large color="grey lighten-1">mdi-information-outline</v-icon>
					<p class="font-weight-bold mt-2">Sélectionnez une règle pour voir les détails</p>
				</div>
			</v-col>
		</v-row>

		<!-- Modal mobile pour les règles -->
		<RulesMobileDialog
			v-if="showMobileDialog && $vuetify.breakpoint.xsOnly"
			:infosRules="infosRules"
			:idFormation="orthographigFormation._id"
			:closeModal="closeMobileDialog"
			@displayRuleFromSession="displayRuleFromSession"
		/>

		<!-- Vue Kanban (affichée uniquement quand viewMode === 'kanban') -->
		<v-dialog
			v-if="!$vuetify.breakpoint.xsOnly && !$vuetify.breakpoint.smOnly"
			v-model="showKanbanView"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			:retain-focus="false"
			content-class="kanban-dialog"
			@click:outside="closeKanbanView"
			@keydown.esc="closeKanbanView"
			@input="onKanbanDialogInput"
		>
			<v-card>
				<v-toolbar class="kamban-toolbar" color="white">
					<v-btn icon @click="closeKanbanView">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title class="title-kamban-toolbar">Règles</v-toolbar-title>
					<v-spacer></v-spacer>
					<KanbanSearch
						ref="kanbanSearch"
						@searchRules="searchRulesFind"
						@searchTextChanged="updateKanbanSearchText"
						class="kanban-search"
					/>
				</v-toolbar>
				<v-card-text class="kanban-container pa-0">
					<RulesKanban
						:rules="rulesDisplay"
						:totalRuleOfFormation="totalRulesOfFormation"
						:nbreRulesView="nbreRulesView"
						:getRuleSelectedName="getRuleSelectedName"
						@rule-clicked="handleRuleClick"
					/>
				</v-card-text>
			</v-card>
		</v-dialog>
		<ModalForRestrictedUser :testTrialData="orthographigFormation.test_trial" modalName="reglesPage" />
		<Loading :show="loading_show" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import RulesCards from './RulesCards';
import RulesInfos from './RulesInfos';
import CardsObjectivesRules from './CardsObjectivesRules';
import SearchAndFilter from './SearchAndFilter';
import Loading from 'vue-full-loading';
import ModalForRestrictedUser from '../../other/ModalForRestrictedUser/ModalForRestrictedUser';
import RulesMobileDialog from './RulesMobileDialog';
import RulesKanban from './RulesKanban';
import KanbanSearch from './KanbanSearch';
import RulesFamilyRadarChart from './RulesFamilyRadarChart';

export default {
	name: 'Index',
	components: {
		RulesCards,
		RulesInfos,
		CardsObjectivesRules,
		SearchAndFilter,
		Loading,
		ModalForRestrictedUser,
		RulesMobileDialog,
		RulesKanban,
		KanbanSearch,
		RulesFamilyRadarChart,
	},
	props: {
		orthographigFormation: { type: Object, required: true },
	},
	computed: {
		...mapGetters('profile', { rulesUser: 'rulesUser' }),
		nbreRulesView() {
			return this.rulesUser.length;
		},
		totalRulesOfFormation() {
			const uniqueParoursId = [];
			this.rulesUser.forEach((rule) => {
				rule.infosExo.forEach((item) => {
					if (!uniqueParoursId.includes(item.exercices_id.cat_id.parcours_id))
						uniqueParoursId.push(item.exercices_id.cat_id.parcours_id);
				});
			});
			return uniqueParoursId.length * 80; // Formation has 1 parcours: 80 rules, 2 parcours: 160, 3 parcours: 240
		},
		getRuleSelectedName() {
			return this.ruleSelected && this.ruleSelected.name ? this.ruleSelected.name : '';
		},
	},
	data() {
		return {
			infosRules: {},
			displayRules: false,
			nbRulesValidated: 0,
			nbRulesMemorized: 0,
			rulesDisplay: null,
			ruleSelected: null,
			statusSelect: 'All',
			familySelect: 'All',
			loading_show: false,
			autoStartTourRules: false,
			autostartRulesConfig: {
				nextLabel: 'Suivant',
				prevLabel: 'Retour',
				skipLabel: 'Sortir',
				doneLabel: 'Terminé',
				showStepNumbers: false,
			},
			showMobileDialog: false,
			viewMode: localStorage.getItem('rulesViewMode') || 'chart',
			showKanbanView: false,
			kanbanSearchText: '',
		};
	},

	watch: {
		rulesDisplay: {
			handler(newRules) {
				// Si en mode liste, qu'on a des règles, et qu'aucune règle n'est déjà sélectionnée
				// On utilise displayRulesInfos au lieu de handleRuleClick pour éviter une boucle
				if (
					this.viewMode === 'list' &&
					newRules &&
					newRules.length > 0 &&
					!this.ruleSelected &&
					(!this.infosRules || !this.infosRules.name)
				) {
					this.$nextTick(() => {
						this.displayRulesInfos(newRules[0]);
					});
				}
			},
			immediate: true,
		},
		viewMode(newValue) {
			// Lorsque le mode de vue change, mettre à jour l'affichage
			if (newValue === 'kanban' && !this.$vuetify.breakpoint.xsOnly && !this.$vuetify.breakpoint.smOnly) {
				this.showKanbanView = true;
				this.displayRules = false;
			} else if (newValue === 'chart') {
				// Mode graphique radar
				this.showKanbanView = false;
				this.displayRules = false;
			} else {
				// Si on est sur mobile et qu'on essaie d'ouvrir la vue Kanban, forcer le mode liste
				if (newValue === 'kanban' && (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly)) {
					this.viewMode = 'list';
					this.showKanbanView = false;
				} else {
					this.showKanbanView = false;
				}
				this.displayRules = true;

				// Si on passe au mode liste sans avoir de règle sélectionnée, sélectionner la première règle
				// mais uniquement si ce n'est pas un clic utilisateur sur une règle spécifique
				if (newValue === 'list' && this.rulesDisplay && this.rulesDisplay.length > 0 && !this.ruleSelected) {
					this.$nextTick(() => {
						// On utilise displayRulesInfos au lieu de handleRuleClick pour éviter une boucle
						this.displayRulesInfos(this.rulesDisplay[0]);
					});
				}
			}
			// Sauvegarder la préférence utilisateur
			localStorage.setItem('rulesViewMode', this.viewMode);
		},
	},
	created() {
		if (localStorage.getItem('tour') == 'rules') this.autoStartTourRules = false;
	},
	async mounted() {
		try {
			await this.$store.dispatch('profile/getRules', { idFormation: this.orthographigFormation._id });
		} catch (error) {
			console.error(error);
		}
		this.getRules();
		this.rulesToDisplay();
		// Vérifier si une règle spécifique a été demandée via l'URL
		if (this.$route.query.rule) {
			this.displayRuleFromSession(this.$route.query.rule);
		}
		// Vérifier si une règle a été sélectionnée via le paramètre selectedRule
		else if (this.$route.query.selectedRule) {
			const selectedRuleId = this.$route.query.selectedRule;
			// Trouver la règle correspondante
			const selectedRule = this.rulesUser.find((rule) => rule.id === selectedRuleId);
			if (selectedRule) {
				// Afficher la règle
				this.displayRulesInfos(selectedRule);
			}
		}
		// Récupérer la préférence de vue de l'utilisateur
		const savedViewMode = localStorage.getItem('rulesViewMode');
		// Sur mobile, toujours forcer la vue liste
		if ((this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly) && savedViewMode === 'kanban') {
			this.viewMode = 'list';
		} else if (savedViewMode) {
			this.viewMode = savedViewMode;
		}
		if (this.$route.query.rules != undefined) {
			this.displayRules = true;
			this.infosRules = this.rulesUser.find((element) => element.id == this.$route.query.rules);
			this.ruleSelected = this.infosRules;
		}
		this.rulesDisplay = this.rulesUser;
		this.countValidatedRules(this.rulesUser);
		this.countMemorizedRules(this.rulesUser);
		if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('dashboard-rules');
	},
	methods: {
		displayRulesInfos(ruleToDisplay) {
			this.infosRules = ruleToDisplay;
			this.displayRules = true;
			this.ruleSelected = ruleToDisplay;
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('dashboard-rules-ruleSelection');
		},
		displayRuleFromSession(value) {
			this.loading_show = true;
			setTimeout(() => {
				this.loading_show = false;
				document.getElementById('rulesInfo').scrollTo(0, 0);
				this.infosRules = this.rulesUser.find((element) => element.name == value);
				this.displayRules = true;
				this.ruleSelected = value;
			}, 1000);
		},
		countValidatedRules(rulesUser) {
			rulesUser.map((rule) => {
				if (rule.score >= 100 && rule.score < 150) this.nbRulesValidated++;
			});
		},
		countMemorizedRules(rulesUser) {
			rulesUser.map((rule) => {
				if (rule.score >= 150) this.nbRulesMemorized++;
			});
		},
		familySelected(value) {
			if (value != 'Toutes les familles') this.familySelect = value;
			else this.familySelect = 'All';

			this.rulesToDisplay();
		},
		statusSelected(value) {
			if (value != 'Tous les statuts') this.statusSelect = value;
			else this.statusSelect = 'All';

			this.rulesToDisplay();
		},
		searchRulesFind(value) {
			if (value === '') {
				// Si le champ de recherche est vide, afficher toutes les règles
				this.rulesDisplay = this.rulesUser;
			} else {
				// Sinon, filtrer les règles selon le texte de recherche
				this.rulesDisplay = this.rulesUser.filter((element) =>
					element.name.toLowerCase().includes(value.toLowerCase())
				);
			}
		},
		rulesToDisplay() {
			if (this.statusSelect != 'All' && this.familySelect != 'All') {
				this.rulesDisplay = this.rulesUser.filter((element) => {
					if (this.statusSelect == 'En cours') {
						return element.score < 100 && element.familyTagName == this.familySelect;
					} else if (this.statusSelect == 'Maîtrisées') {
						return element.score >= 100 && element.score < 150 && element.familyTagName == this.familySelect;
					} else if (this.statusSelect == 'Mémorisées') {
						return element.score >= 150 && element.familyTagName == this.familySelect;
					}
				});
			} else if (this.statusSelect == 'All' && this.familySelect != 'All') {
				this.rulesDisplay = this.rulesUser.filter((element) => {
					return element.familyTagName == this.familySelect;
				});
			} else if (this.statusSelect != 'All' && this.familySelect == 'All') {
				this.rulesDisplay = this.rulesUser.filter((element) => {
					if (this.statusSelect == 'En cours') {
						return element.score < 100;
					} else if (this.statusSelect == 'Maîtrisées') {
						return element.score >= 100 && element.score < 150;
					} else if (this.statusSelect == 'Mémorisées') {
						return element.score >= 150;
					}
				});
			} else if (this.statusSelect == 'All' && this.familySelect == 'All') {
				this.rulesDisplay = this.rulesUser;
			}
		},
		handleRuleClick(rule) {
			// Basculer en mode liste si nous sommes dans un autre mode (chart ou kanban)
			if (this.viewMode !== 'list') {
				// Sauvegarder le mode actuel avant de basculer en mode liste pour pouvoir y revenir
				this.viewMode = 'list';
			}

			if (this.$vuetify.breakpoint.xsOnly) {
				this.infosRules = rule;
				this.ruleSelected = rule;
				this.showMobileDialog = true;
			} else {
				// Si on est dans la vue Kanban, on la ferme d'abord
				if (this.showKanbanView) {
					// Sauvegarder la valeur de recherche du Kanban pour l'appliquer à la vue normale
					const searchTextToTransfer = this.kanbanSearchText;
					// Réinitialiser la recherche dans le Kanban avant de fermer la vue
					this.$refs.kanbanSearch.resetSearch();
					this.closeKanbanView();
					// Petit délai pour laisser la vue se fermer avant d'afficher les détails
					setTimeout(() => {
						this.displayRulesInfos(rule);
						// Si une recherche était active dans le Kanban, l'appliquer aussi dans la vue normale
						if (searchTextToTransfer) {
							// Mettre à jour la recherche dans la vue normale
							this.searchRulesFind(searchTextToTransfer);
							// Mettre à jour le champ de recherche dans le composant SearchAndFilter
							this.$nextTick(() => {
								const searchAndFilterComponent = this.$refs.searchAndFilter;
								if (searchAndFilterComponent) {
									searchAndFilterComponent.updateSearchText(searchTextToTransfer);
								}
							});
						}
					}, 300);
				} else {
					this.displayRulesInfos(rule);
				}
			}
		},
		closeMobileDialog() {
			this.showMobileDialog = false;
		},
		onFinishTourRules() {
			localStorage.setItem('tour', 'rules');
		},
		getRules() {
			// This method is empty, you might want to add some logic here
		},
		closeKanbanView() {
			// Réinitialiser la recherche dans le Kanban avant de fermer la vue
			this.kanbanSearchText = '';
			// Trouver le composant KanbanSearch et réinitialiser sa recherche
			this.$refs.kanbanSearch.resetSearch();
			// Fermer la vue Kanban
			this.showKanbanView = false;
			this.viewMode = 'list';
		},
		updateKanbanSearchText(text) {
			this.kanbanSearchText = text;
		},
		onKanbanDialogInput(value) {
			if (!value) {
				this.closeKanbanView();
			}
		},
	},
};
</script>

<style lang="scss">
.title-kamban-toolbar {
	font-weight: 600;
}
.kamban-toolbar {
	box-shadow: initial !important;
}

.tooltip-Css {
	font-family: 'Lato';
	h2 {
		color: #2dc7ae;
		font-weight: bold;
		font-size: 20px;
	}
}
.blockRules,
#rulesInfo {
	height: 90vh;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch; // Améliore le scroll sur iOS
}

.hide-scroll-bar {
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

.CardsObjectivesRules-mobile {
	display: none;
}

@media only screen and (max-width: 600px) {
	.rules-info-col {
		display: none;
	}
	#imgDirection {
		display: none;
	}
	.blockRules {
		height: calc(100vh - 60px); // Hauteur de l'écran moins le padding du bas
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 16px;
	}
	.CardsObjectivesRules-desktop {
		display: none;
	}
	.rulesInfo-instruction-desktop {
		display: none;
	}
	.CardsObjectivesRules-mobile {
		display: flex;
		margin-bottom: 20px;
	}
}

.view-selector {
	display: flex;
	justify-content: center;
}

.view-toggle {
	width: 100%;
	.v-btn {
		flex: 1;
	}
}

.kanban-container {
	height: calc(100vh - 64px);
	overflow-y: auto;
	background-color: #f5f5f5;
}

.kanban-search {
	max-width: 300px;
}

@media screen and (max-width: 960px) {
	.kanban-search {
		max-width: 200px;
	}
}

@media screen and (max-width: 600px) {
	.kanban-search {
		display: none;
	}

	.kanban-container {
		height: calc(100vh - 56px);
	}
}

// Style pour le conteneur du graphique radar
.radar-chart-container {
	width: 100%;
	margin-top: 30px;
}

.kanban-dialog {
	margin-top: 3rem !important;
}
</style>
